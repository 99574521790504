/* eslint-disable vue/max-len, max-len */
import httpClient from '@/api/http-client';
import debounce from 'lodash.debounce';

// const validEmailAddressRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_` + "`" + `{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_` + "`" + `{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
const validEmailAddressRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
let requestEmailAddress = '';
let requestResolvers = [] as ((value: boolean) => void)[];

const validateEmailAddressDebounced = debounce(
  async (emailAddress: string) => {
    let valid = false;
    try {
      valid = await httpClient.validateEmailAddress(emailAddress);
    } catch (e) {
      console.log('validateEmailAddress error:', e);
    }
    if (requestEmailAddress === emailAddress) { requestResolvers.forEach((rr) => rr(valid)); requestResolvers = []; }
  },
  300,
  { leading: false, trailing: true },
);

// eslint-disable-next-line import/prefer-default-export
export async function validateEmailAddress(emailAddress: string): Promise<boolean> {
  if (!emailAddress.match(validEmailAddressRegex)) return false;

  return new Promise((resolve) => {
    requestEmailAddress = emailAddress;
    requestResolvers.push(resolve);
    validateEmailAddressDebounced(emailAddress);
  });
}
